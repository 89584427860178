/* Custom Theming for Angular Material */
@use '@angular/material' as mat;
@include mat.core();
$datalean-fe-3_0-primary: mat.define-palette(mat.$indigo-palette);
$datalean-fe-3_0-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$datalean-fe-3_0-warn: mat.define-palette(mat.$red-palette);
$datalean-fe-3_0-theme: mat.define-light-theme((color: (primary: $datalean-fe-3_0-primary,
				accent: $datalean-fe-3_0-accent,
				warn: $datalean-fe-3_0-warn,
			),
		));
@include mat.all-component-themes($datalean-fe-3_0-theme);

/* You can add global styles to this file, and also import other style files */
@import 'mixins.scss';
@import 'standard-mixins.scss';
@import 'form-fields.scss';
@import 'fonts.scss';
@import './app/theme/addiction-components/addiction-autocomplete.scss';
@import './app/theme/addiction-components/addiction-checkbox.scss';
@import './app/theme/addiction-components/addiction-bulk-action-bar.scss';
@import './app/theme/addiction-components/addiction-chip.scss';
@import './app/theme/addiction-components/addiction-confirmation-message.scss';
@import './app/theme/addiction-components/addiction-dam-header.scss';
@import './app/theme/addiction-components/addiction-date-picker.scss';
@import './app/theme/addiction-components/addiction-email.scss';
@import './app/theme/addiction-components/addiction-filter-menu.scss';
@import './app/theme/addiction-components/addiction-grid.scss';
@import './app/theme/addiction-components/addiction-grid-options.scss';
@import './app/theme/addiction-components/addiction-html-editor.scss';
@import './app/theme/addiction-components/addiction-input.scss';
@import './app/theme/addiction-components/addiction-list.scss';
@import './app/theme/addiction-components/addiction-list-draggable.scss';
@import './app/theme/addiction-components/addiction-password-form.scss';
@import './app/theme/addiction-components/addiction-phone.scss';
@import './app/theme/addiction-components/addiction-radio.scss';
@import './app/theme/addiction-components/addiction-search.scss';
@import './app/theme/addiction-components/addiction-select.scss';
@import './app/theme/addiction-components/addiction-simple-registration.scss';
@import './app/theme/addiction-components/addiction-textarea.scss';
@import './app/theme/addiction-components/addiction-tree-browser.scss';

/* IMPORT FILE SCSS CHE ANDRANNO RINOMINATI DA DATALEAN AD ADDICTION */
@import './app/theme/addiction-components/datalean-input-dialog.scss';
@import './app/theme/addiction-components/datalean-modal-header.scss';
@import './app/theme/addiction-components/datalean-simple-list.scss';
@import '~jsoneditor/dist/jsoneditor.min.css';

html,
body {
	height: 100%;
}

body {
	margin: 0;
	font-family: $font-default;
	background: $brand-color-01;
	overflow: hidden;
}

* {
	box-sizing: border-box;
	transition: $standard-transition;
	color: $white;
}

a,
input,
button,
form,
textarea {
	outline: none;
	text-decoration: none;

	&:focus-visible {
		outline: none;
	}
}

input,
textarea,
select {

	&:-webkit-autofill,
	&:-webkit-autofill:hover,
	&:-webkit-autofill:focus {
		border: 1px solid rgba(31, 43, 67, 0.7);
		-webkit-text-fill-color: rgb(255, 255, 255);
		-webkit-box-shadow: 0 0 0 1000px rgba(31, 43, 67, 0.7) inset;
		transition: background-color 5000s ease-in-out 0s;
	}

}

img {
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	user-drag: none;
}

.editor-alert-container {
	width: 100%;
	display: flex;
	flex-flow: column;
	padding: 0 10px;
	gap: 20px;
	margin-bottom: 20px;

	.alert {
		width: 100%;
		color: $white;
		padding: 10px 15px;
		border-radius: 5px;
		background-color: $ko-color;
		text-align: center;
	}

}

.cdk-overlay-backdrop {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: transparentize($brand-color-01, 0.5);
	backdrop-filter: blur(10px) brightness(0.85);
	-webkit-backdrop-filter: blur(10px) brightness(0.85);
}

.cdk-overlay-container {

	* {
		transition: none;
	}

	.cdk-global-overlay-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		@include flex-center;

		.cdk-overlay-pane {
			max-height: calc(100% - 80px);
			max-width: calc(100% - 80px);

      // FULL SCREEN VARIANT
      &.fullscreen,
      &.versioning-popup {
        width: 100%;
        max-width: 100%;
        height: 100%;
        max-height: 100%;

        .mat-mdc-dialog-container {
          width: 100%;
          height: 100%;
          max-height: 100%;
          padding: 0;

          .mdc-dialog__container {
            .mdc-dialog__surface {
              modal-dialog {
                .modal-dialog {

                  datalean-modal-header {
                    padding: 30px 20px 0 30px;

                    .modal-title {
                      margin: 0;
                    }

                  }

                  .modal-content {
                    padding: 0 0 20px 0;
                  }

                }
              }
            }
          }
        }

      }

			.mat-mdc-dialog-container {
				height: auto;
				min-height: unset;
				max-height: 800px;
				max-width: 100%;

				.mdc-dialog__container {

					.mat-mdc-dialog-surface {

						modal-dialog {

							.modal-dialog {

								modal-header {}

								.modal-content {

									datalean-tree-selector-dialog {

										datalean-tree-browser {
											height: calc(100% - 60px);

											.top-bar {
												height: 60px;

												addiction-search {
													addiction-input {
														.form-field {
															.form-input-container {
																width: 100%;
															}
														}
													}
												}

												.buttons {
													display: none;
												}

											}

											.tree-browser {
												height: calc(100% - 60px);
											}

										}

										>div {
											width: 100%;
											height: 60px;
											padding-top: 20px;
											display: flex;
											justify-content: center;

											button {
												//Button Aggiungi
												height: 40px;
												min-width: 150px;
												border: none;
												background: $brand-color-04;
												border-radius: 10px;
												@include font-style(12px, $font-semibold, $white, 2.5px, 100%);
												text-transform: uppercase;
												cursor: pointer;
												transition: $standard-transition;

												@media (hover: hover) and (pointer: fine) {

													&:hover {
														background: $white;
														color: $brand-color-04;
													}

												}

											}

										}

									}

								}

							}

						}

					}

				}

			}

		}

	}

	/* ES. POPUP PER NUOVA IMMAGINE + POPUP PER NUOVA CARTELLA NELLA MEDIA LIBRARY*/
	mat-dialog-container {
		background-color: $brand-color-02;
		padding: 20px;
		width: 600px;
		height: 100%;
		display: flex;
		flex-direction: column;

		.mdc-dialog__container {
			width: 100%;
			height: 100%;

			.mat-mdc-dialog-surface {
				background: $transparent;
				box-shadow: none;
				overflow: hidden;

				modal-dialog {
					//da qui in poi, stile nel file modal-dialog.component.scss
				}

			}

		}

	}

	.cdk-overlay-connected-position-bounding-box {

		.cdk-overlay-pane {
			transform: translateX(0) !important;

			/* POPUP PER SELECT */
			.mat-mdc-select-panel {
				min-width: calc(100% - 15px) !important;
				background: $brand-color-15;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				box-shadow: none;
				@include scroll-bar-dark;

				.mat-mdc-option {
					padding: 0 10px;
					height: 40px;
					background: $transparent !important;

					@media (hover: hover) and (pointer: fine) {

						&:hover {

							.mdc-list-item__primary-text {
								color: $white;
							}

						}

					}

					&.mdc-list-item--selected {

						.mdc-list-item__primary-text {
							color: $white;
						}

					}

					.mdc-list-item__primary-text {
						@include font-style(12px, $font-medium, transparentize($white, 0.5), 1.2px, 170%);
						cursor: pointer;
						transition: $standard-transition;
					}

					mat-pseudo-checkbox {
						width: 18px;
						height: 18px;
						border: none;
						background: $brand-color-06;
						border-radius: 5px;
						@include flex-center;

						&.mat-pseudo-checkbox-checked {
							background: $brand-color-04;

							&::before {
								opacity: 1;
							}

						}

						&::before {
							content: '';
							width: 7px;
							height: 4px;
							position: absolute;
							border-bottom: 2px solid $white;
							border-left: 2px solid $white;
							margin-bottom: 2px;
							transform: rotate(-45deg);
							opacity: 0;
							z-index: 1;
							cursor: pointer;
							transition: $standard-transition;
						}

						&::after {
							display: none;
						}

					}

					.mat-ripple {
						display: none;
					}

				}

			}

			/* POPUP PER MENU */
			/* es. user-menu */
			.mat-mdc-menu-panel {
				width: 100%;
				min-width: 150px;
				max-height: 600px;
				background: $brand-color-03;
				border-radius: 5px;
				box-shadow: none;
				@include scroll-bar;

				.mat-mdc-menu-content {
					padding: 5px 0;

					.mat-mdc-menu-item {
						padding: 10px 20px;
						min-height: 40px;
						transition: $standard-transition;
						cursor: pointer;

						@media (hover: hover) and (pointer: fine) {

							&:hover {
								background: $transparent !important;

								&.with-icon {

									&::before {
										opacity: 1;
									}

								}

								&.DELETE,
								&.INVALID,
								&.RESET {

									.mat-mdc-menu-item-text {
										color: $red-01;

										label.date-picker,
										span {
											color: $red-01;
										}

									}

								}

								.mat-mdc-menu-item-text {
									color: $white;

									label.date-picker,
									span {
										color: $white;
									}

								}

							}

						}

						&.with-icon {
							padding: 0 20px 0 50px;

							&::before {
								width: 16px;
								height: 16px;
								font-size: 16px;
								color: $white;
								font-family: $font-icon;
								position: absolute;
								cursor: pointer;
								@include flex-center;
								left: 20px;
								top: unset;
								bottom: unset;
								right: unset;
								margin: 0;
								border: none;
								border-radius: 0;
								opacity: 0.4;
								transition: $standard-transition;
							}

              $language-images: (
                "ar-SA": "/assets/images/languages/ar-SA.png",
                "cs-CZ": "/assets/images/languages/cs-CZ.png",
                "de-DE": "/assets/images/languages/de-DE.png",
                "en-AU": "/assets/images/languages/en-AU.png",
                "en-UK": "/assets/images/languages/en-UK.png",
                "en-US": "/assets/images/languages/en-US.png",
                "es-ES": "/assets/images/languages/es-ES.png",
                "fr-CA": "/assets/images/languages/fr-CA.png",
                "fr-FR": "/assets/images/languages/fr-FR.png",
                "it-IT": "/assets/images/languages/it-IT.png",
                "ja-JP": "/assets/images/languages/ja-JP.png",
                "ko-KR": "/assets/images/languages/ko-KR.png",
                "pl-PL": "/assets/images/languages/pl-PL.png",
                "pt-PT": "/assets/images/languages/pt-PT.png",
                "ru-RU": "/assets/images/languages/ru-RU.png",
                "tr-TR": "/assets/images/languages/tr-TR.png",
                "zh-CN": "/assets/images/languages/zh-CN.png"
              );

              @each $lang, $path in $language-images {
                &.#{$lang}::before {
                  content: '';
                  background: url($path);
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: contain;
                }
              }

						}

						&.DELETE,
						&.INVALID,
						&.RESET {

							.mat-mdc-menu-item-text {
								color: transparentize($red-01, 0.5);

								label.date-picker,
								span {
									color: transparentize($red-01, 0.5);
								}

							}

						}

						.mat-mdc-menu-item-text {
							width: 100%;
							position: relative;
							display: flex;
							justify-content: flex-start;
							align-items: center;
							flex-flow: row wrap;
							@include font-style(12px, $font-medium, transparentize($white, 0.5), 1.2px, 170%);
							cursor: pointer;
							transition: $standard-transition;

							label.date-picker {
								/* quando c'è date-picker */
								@include font-style(10px, $font-medium, transparentize($white, 0.5), 2.5px, 100%);
								text-transform: uppercase;
								margin-bottom: 5px;
								cursor: pointer;
								transition: $standard-transition;
							}

							mat-checkbox {
								width: auto;
								@include mat-checkbox-style(transparentize($white, 0.9), $primary, none);

								.mdc-form-field {

									.mdc-label {
										@include font-style(12px, $font-medium, $white, 1.2px, 170%);
									}

								}

							}

							span {
								@include font-style(12px, $font-medium, transparentize($white, 0.5), 1.2px, 170%);
								margin-right: auto;
								cursor: pointer;
								transition: $standard-transition;
							}

							addiction-radio {
								height: auto;

								.form-field.radio {

									mat-radio-group {
										flex-flow: column;
										row-gap: 20px;

										mat-radio-button {

											.mdc-form-field {

												.mdc-label {
													@include font-style(12px, $font-medium, transparentize($white, 0.5), 1.2px, 170%);
												}

											}

										}

									}

								}

							}

						}

						.mat-ripple {
							display: none;
						}

						.mat-mdc-menu-submenu-icon {
							display: none;
						}

						&.mat-mdc-menu-item-submenu-trigger {

							.mat-mdc-menu-item-text {
								padding-right: 10px;

								&::before {
									content: 'a';
									width: 10px;
									height: 10px;
									font-size: 7px;
									font-family: $font-icon;
									position: absolute;
									right: -10px;
									cursor: pointer;
									transition: $standard-transition;
									@include flex-center;
									transform: rotate(180deg);
								}

							}

						}

					}

				}

			}

			/* POPUP COMPONENTE <addiction-date-picker> */
			&.mat-datepicker-popup {

				* {
					color: $white;
					fill: $white;
				}

				.mat-datepicker-content {
					background: $brand-color-03;

					.mat-datepicker-content-container {

						mat-calendar {

							mat-calendar-header {

								.mat-calendar-header {

									.mat-calendar-controls {
										margin: 0;

										>button {}

									}

								}

							}

							.mat-calendar-content {

								mat-month-view {

									.mat-calendar-table {

										.mat-calendar-table-header {
											display: none;
										}

										.mat-calendar-body {

											.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
												border-color: transparentize($white, 0.6);
											}

										}

									}

								}

							}

						}

						.mat-datepicker-close-button {}

					}

				}

			}

		}

		/* STILE TOOLTIP */
		.mat-mdc-tooltip-panel {

			mat-tooltip-component {

				.mdc-tooltip {

					.mdc-tooltip__surface {
						@include font-style(10px, $font-medium, $white, 0, 150%);
						background: $primary;
						border-radius: 5px;
						position: relative;
						padding: 5px 10px;
						margin-left: 10px;
						overflow: visible;

						&::before {
							content: '';
							width: 10px;
							height: 10px;
							transform: rotate(-90deg);
							border-width: 6px;
							border-style: solid;
							border-color: transparent transparent $primary transparent;
							position: absolute;
							top: unset;
							left: -11px;
							margin-top: 1px;
						}

					}

				}

			}

		}

	}

}

.overlay-container {

	#toast-container {

		>div {
			width: calc(100% - 40px);
			margin: 10px 20px 0 20px;
			padding: 10px 25px;
			border-radius: 0;

			&.toast-error {
				background: $ko-color;
				box-shadow: none;
			}

			&.toast-success {
				background: $ok-color;
				box-shadow: none;
			}

			.toast-message {
				@include font-style(14px, $font-regular, $white, 1px, 170%);
				cursor: pointer;
				position: relative;

				&::before {
					content: 'd';
					width: 12px;
					height: 12px;
					font-size: 12px;
					color: $white;
					font-family: $font-icon;
					position: absolute;
					right: 0;
					cursor: pointer;
					transition: $standard-transition;
				}

			}

		}

	}

}

.page-loader-container {
  width: 100%;
  height: 100%;
  @include flex-center;
  flex-flow: column;

  ng-lottie {
    width: 300px;
    height: 300px;
  }

}
