/* --- TYPOGRAPHY --- */
$font-default:					    'Segma', sans-serif;
$font-icon:			 			      'DataLean Font Icon';
$font-icon-structure:       'DataLean Structure Font Icon';
$font-icon-html:            'DataLean HTML Font Icon';

$font-thin:             		100;
$font-extralight:        		200;
$font-light:             		300;
$font-regular:           		400;
$font-medium:          		  500;
$font-semibold:          		600;
$font-bold:              		700;
$font-extrabold:           	800;
$font-black:              	900;

/* --- COLORS --- */
$black:							        #000000;
$white:							        #FFFFFF;
$transparent:               #FFFFFF00;
$ko-color:					        #A20000; //da controllare
$ok-trans-color:            rgba(255, 0, 0, 0.25);
$ok-color:				          #4F8A10; //da controllare

$off-color:					        #FF3D00;
$red-01:					          #FF4848;
$red-02:					          #ff9595;
$on-color:				          #42BD41;
$pending-color:		          #FF9A00;
$grey-01:		                #707070;


$brand-color-01:            #09152C; //Background pagine,
$brand-color-02:            #132039; //Background contenuto attivo,
$brand-color-03:            #01286C; //Background popup,
$brand-color-04:            #004BC4; //Background cartelle attive,
$brand-color-05:            #004BC433; //Background cartelle non attive,
$brand-color-06:            rgb(44, 53, 70); //transparentize($white, 0.9) con sfondo $brand-color-02
$brand-color-07:            #1F3255; //Linea separatrice
$brand-color-08:            #3d5d96; //Linea dimensione file media library
$brand-color-09:            #71A7FF; //hover bottone edit image dettaglio prodotto
$brand-color-10:            #212d44;
$brand-color-11:            #192947; //linea separatrice lista prodotti
$brand-color-12:            #1F2B43;
$brand-color-13:            #0E1B34;

//$brand-color-14:            #337BFF;
$brand-color-14:            #0061FF;
$brand-color-15:            rgb(35, 46, 70); //sfondo fields senza opacity
$brand-color-16:            #2B374D; //Sfondo select
$brand-color-17:            #2C3446; //Sfondo html editor

$primary:                   $brand-color-04;
$secondary:                 $brand-color-03;
$tertiary:                  $brand-color-01;


/* --- ANIMATIONS --- */
$bezier:                    cubic-bezier(0.090, 0.955, 0.450, 0.985);
$standard-transition:       all 0.5s $bezier;
