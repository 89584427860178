/* SEGMA FONT */
@font-face {
  font-family: 'Segma';
  src: url('assets/fonts/Segma/Segma-Thin.eot');
  src: url('assets/fonts/Segma/Segma-Thin.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Segma/Segma-Thin.woff2') format('woff2'),
  url('assets/fonts/Segma/Segma-Thin.woff') format('woff'),
  url('assets/fonts/Segma/Segma-Thin.ttf') format('truetype'),
  url('assets/fonts/Segma/Segma-Thin.svg#Segma-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('assets/fonts/Segma/Segma-Light.eot');
  src: url('assets/fonts/Segma/Segma-Light.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Segma/Segma-Light.woff2') format('woff2'),
  url('assets/fonts/Segma/Segma-Light.woff') format('woff'),
  url('assets/fonts/Segma/Segma-Light.ttf') format('truetype'),
  url('assets/fonts/Segma/Segma-Light.svg#Segma-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('assets/fonts/Segma/Segma-Regular.eot');
  src: url('assets/fonts/Segma/Segma-Regular.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Segma/Segma-Regular.woff2') format('woff2'),
  url('assets/fonts/Segma/Segma-Regular.woff') format('woff'),
  url('assets/fonts/Segma/Segma-Regular.ttf') format('truetype'),
  url('assets/fonts/Segma/Segma-Regular.svg#Segma-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('assets/fonts/Segma/Segma-Medium.eot');
  src: url('assets/fonts/Segma/Segma-Medium.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Segma/Segma-Medium.woff2') format('woff2'),
  url('assets/fonts/Segma/Segma-Medium.woff') format('woff'),
  url('assets/fonts/Segma/Segma-Medium.ttf') format('truetype'),
  url('assets/fonts/Segma/Segma-Medium.svg#Segma-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('assets/fonts/Segma/Segma-SemiBold.eot');
  src: url('assets/fonts/Segma/Segma-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Segma/Segma-SemiBold.woff2') format('woff2'),
  url('assets/fonts/Segma/Segma-SemiBold.woff') format('woff'),
  url('assets/fonts/Segma/Segma-SemiBold.ttf') format('truetype'),
  url('assets/fonts/Segma/Segma-SemiBold.svg#Segma-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('assets/fonts/Segma/Segma-Bold.eot');
  src: url('assets/fonts/Segma/Segma-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Segma/Segma-Bold.woff2') format('woff2'),
  url('assets/fonts/Segma/Segma-Bold.woff') format('woff'),
  url('assets/fonts/Segma/Segma-Bold.ttf') format('truetype'),
  url('assets/fonts/Segma/Segma-Bold.svg#Segma-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Segma';
  src: url('assets/fonts/Segma/Segma-Black.eot');
  src: url('assets/fonts/Segma/Segma-Black.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/Segma/Segma-Black.woff2') format('woff2'),
  url('assets/fonts/Segma/Segma-Black.woff') format('woff'),
  url('assets/fonts/Segma/Segma-Black.ttf') format('truetype'),
  url('assets/fonts/Segma/Segma-Black.svg#Segma-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

/* MEDIA LIBRARY - DATALEAN 3.0 FONT ICON */
@font-face {
  font-family: 'DataLean Font Icon';
  src: url('assets/fonts/FontIcon/icomoon.eot?qsdupw');
  src: url('assets/fonts/FontIcon/icomoon.eot?qsdupw#iefix') format('embedded-opentype'),
    url('assets/fonts/FontIcon/icomoon.woff2?qsdupw') format('woff2'),
    url('assets/fonts/FontIcon/icomoon.ttf?qsdupw') format('truetype'),
    url('assets/fonts/FontIcon/icomoon.woff?qsdupw') format('woff'),
    url('assets/fonts/FontIcon/icomoon.svg?qsdupw#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'DataLean Font Icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-back::before {
  content: "\61"; //a
}
.icon-arrow::before {
  content: "\62"; //b
}
.icon-checked::before {
  content: "\63"; //c
}
.icon-close::before {
  content: "\64"; //d
}
.icon-plus::before {
  content: "\65"; //e
}
.icon-minus::before {
  content: "\66"; //f
}
.icon-move::before {
  content: "\67"; //g
}
.icon-search::before {
  content: "\68"; //h
}
.icon-delete::before {
  content: "\69"; //i
}
.icon-video::before {
  content: "\6a"; //j
}
.icon-copy::before {
  content: "\6b"; //k
}
.icon-order::before {
  content: "\6c"; //l
}
.icon-zoom::before {
  content: "\6d"; //m
}
.icon-edit::before {
  content: "\6e"; //n
}
.icon-date::before {
  content: "\6f"; //o
}
.icon-image::before {
  content: "\70"; //p
}
.icon-doc::before {
  content: "\71"; //q
}
.icon-bell::before {
  content: "\72"; //r
}
.icon-folder-empty::before {
  content: "\73"; //s
}
.icon-folder-filled::before {
  content: "\74"; //t
}
.icon-upload::before {
  content: "\75"; //u
}
.icon-collections::before {
  content: "\76"; //v
}
.icon-configuration::before {
  content: "\77"; //w
}
.icon-app-switch::before {
  content: "\78"; //x
}
.icon-filters::before {
  content: "\79"; //y
}
.icon-grid::before {
  content: "\7a"; //z
}
.icon-list::before {
  content: "\41"; //A
}
.icon-image1::before {
  content: "\42"; //B
}
.icon-video1::before {
  content: "\43"; //C
}
.icon-doc1::before {
  content: "\44"; //D
}
.icon-archive::before {
  content: "\45"; //E
}
.icon-unknown::before {
  content: "\46"; //F
}
.icon-ban::before {
  content: "\47"; //G
}
.icon-contextual::before {
  content: "\48"; //H
}
.icon-permissions::before {
  content: "\49"; //I
}
.icon-replace::before {
  content: "\4a"; //J
}
.icon-download::before {
  content: "\4b"; //K
}
.icon-qrcode::before {
  content: "\4c"; //L
}
.icon-user::before {
  content: "\4d"; //M
}
.icon-challenge::before {
  content: "\4e"; //N
}
.icon-eye-opened::before {
  content: "\4f"; //O
}
.icon-eye-closed::before {
  content: "\50"; //P
}

@font-face {
  font-family: 'DataLean Structure Font Icon';
  src: url('assets/fonts/FontIconStrutture/icomoon.eot?or13gt');
  src: url('assets/fonts/FontIconStrutture/icomoon.eot?or13gt#iefix') format('embedded-opentype'),
  url('assets/fonts/FontIconStrutture/icomoon.woff2?or13gt') format('woff2'),
  url('assets/fonts/FontIconStrutture/icomoon.ttf?or13gt') format('truetype'),
  url('assets/fonts/FontIconStrutture/icomoon.woff?or13gt') format('woff'),
  url('assets/fonts/FontIconStrutture/icomoon.svg?or13gt#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'DataLean Structure Font Icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-select::before { //a
  content: "\61";
}
.icon-container::before { //b
  content: "\62";
}
.icon-file::before { //c
  content: "\63";
}
.icon-textarea::before { //d
  content: "\64";
}
.icon-html::before { //e
  content: "\65";
}
.icon-email::before { //f
  content: "\66";
}
.icon-checkbox::before { //g
  content: "\67";
}
.icon-qr-code::before { //h
  content: "\68";
}
.icon-filter::before { //i
  content: "\69";
}
.icon-privacy:before { //j
  content: "\6a";
}
.icon-media::before { //k
  content: "\6b";
}
.icon-text::before { //l
  content: "\6c";
}
.icon-hotspotter::before { //m
  content: "\6d";
}
.icon-radio::before { //n
  content: "\6e";
}
.icon-related::before { //o
  content: "\6f";
}
.icon-phone::before { //p
  content: "\70";
}
.icon-attachment::before { //q
  content: "\71";
}
.icon-url-title::before { //r
  content: "\72";
}
.icon-toggle::before { //s
  content: "\73";
}
.icon-image-map::before { //t
  content: "\74";
}
.icon-categories::before { //u
  content: "\75";
}
.icon-date::before { //v
  content: "\76";
}
.icon-number::before { //w
  content: "\77";
}
.icon-hidden::before { //x
  content: "\78";
}
.icon-pictograms::before { //y
  content: "\79";
}
.icon-survery-domain-for-answers::before { //z
  content: "\7a";
}
.icon-substructure::before { //A
  content: "\41";
}
.icon-availability-option::before { //B
  content: "\42";
}
.icon-availabiity-rule::before { //C
  content: "\43";
}


@font-face {
  font-family: 'DataLean HTML Font Icon';
  src:  url('assets/fonts/FontIconHtml/icomoon.eot?1oa1r1');
  src:  url('assets/fonts/FontIconHtml/icomoon.eot?1oa1r1#iefix') format('embedded-opentype'),
  url('assets/fonts/FontIconHtml/icomoon.woff2?1oa1r1') format('woff2'),
  url('assets/fonts/FontIconHtml/icomoon.ttf?1oa1r1') format('truetype'),
  url('assets/fonts/FontIconHtml/icomoon.woff?1oa1r1') format('woff'),
  url('assets/fonts/FontIconHtml/icomoon.svg?1oa1r1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: 'DataLean HTML Font Icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-html-bold::before {
  content: "\61"; //a
}
.icon-html-italic::before {
  content: "\62"; //b
}
.icon-html-underline::before {
  content: "\63"; //c
}
.icon-html-strike::before {
  content: "\64"; //d
}
.icon-html-ordered-list::before {
  content: "\65"; //e
}
.icon-html-bullet-list::before {
  content: "\66"; //f
}
.icon-html-link::before {
  content: "\67"; //g
}
.icon-html-text-color::before {
  content: "\68"; //h
}
.icon-html-bg-color::before {
  content: "\69"; //i
}
.icon-html-text-left::before {
  content: "\6a"; //j
}
.icon-html-text-center::before {
  content: "\6b"; //k
}
.icon-html-text-right::before {
  content: "\6c"; //l
}
.icon-html-text-justify::before {
  content: "\6d"; //m
}
.icon-html-superscript::before {
  content: "\6e"; //n
}
.icon-html-subscript::before {
  content: "\6f"; //o
}
.icon-html-table::before {
  content: "\70"; //p
}
.icon-html-add-row::before {
  content: "\71"; //q
}
.icon-html-add-column::before {
  content: "\72"; //r
}
.icon-html-delete-row::before {
  content: "\73"; //s
}
.icon-html-delete-column::before {
  content: "\74"; //t
}
.icon-html-delete-table::before {
  content: "\75"; //u
}
