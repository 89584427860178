@import "src/mixins.scss"; //ok
@import "src/standard-mixins.scss";

/* ADDICTION GRID */
addiction-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  &.show-bulk-actions {
    height: calc(100% - 70px);
  }

  .header-grid-container { /* uguale ad addiction-list.scss */
    width: 100%;
    min-height: auto;
    @include flex-center;
    flex-flow: row wrap;
    position: relative;

    &.expanded {
      margin-bottom: 70px;
    }

    &.dam {

      .header-grid {

        .left-container {

          .title {

            h1 {
              @include font-style(40px, $font-light, $white, 0, 100%);
            }

          }

        }

      }

    }

    .header-grid {
      width: 100%;
      height: 70px;
      padding: 10px 20px;
      display: flex;

      .left-container {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: auto;

        .checkbox-container {
          width: 30px;

          mat-checkbox {
            @include mat-checkbox-style(transparentize($white, 0.9), $primary, none);
            margin: 0 10px 0 0;
          }

        }

        .title {
          width: calc(100% - 30px);

          h1 {
            @include font-style(20px, $font-light, $white, 0, 150%);
            text-overflow: ellipsis;
            overflow: hidden;
            margin: 0;
          }

        }

      }

      .right-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        addiction-search { /* stile dentro addiction-components.scss */ }

        button.new {
          @include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);
          margin-left: 20px;
        }

        .elements {
          display: flex;
          justify-content: flex-end;

          .number {
            @include font-style(14px, $font-regular, $white, 1px, 100%);
            margin-right: 5px;
          }

          .text {
            @include font-style(14px, $font-regular, $white, 1px, 100%);
          }

        }

      }

    }

    addiction-bulk-action-bar { /* stile dentro addiction-components.scss */ }

  }

  .grid-viewport {
    width: 100%;
    height: calc(100% - 20px);
    margin-top: 20px;
    padding: 0 10px;
    overflow-y: auto;
    overflow-x: hidden;
    @include scroll-bar;

    &.with-header {
      height: calc(100% - 70px);
      margin-top: 0;
    }

    &.hidden {
      display: none;
    }

    .grid-row {
      display: flex;
      width: 100%;
      height: auto;
      //column-gap: 20px;
      margin-bottom: 20px;

      .grid-card {
        height: auto;
        flex: 1;
        padding: 0 10px;
        display: flex;
        align-items: flex-start;
        justify-content: center;

        .card {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          cursor: pointer;

          &.folders,
          &.smart-widget,
          &.collections {
            width: 100%;
            aspect-ratio: 5 / 4;
            background-image: url("../../../assets/images/folder-border.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom;
            padding: 10px;
            display: flex;
            justify-content: flex-end;
            position: relative;
            transition: $standard-transition;

            @media (hover: hover) and (pointer: fine) {

              &:hover {
                opacity: 0.5;
              }

            }

            //.lock {
            //
            //&::before {
            //  content: "o";
            //  width: 30px;
            //  height: 30px;
            //  font-size: 30px;
            //  font-family: $font-icon;
            //  color: $brand-color-02;
            //  position: absolute;
            //  pointer-events: none;
            //  bottom: calc(65% - 30px);
            //  right: 10px;
            //
            //  @media screen and (max-width: 1500px) {
            //    width: 20px;
            //    height: 20px;
            //    font-size: 20px;
            //    bottom: calc(65% - 20px);
            //  }
            //
            //}
            //
            //}

            //.menu {
            //  width: 20px;
            //  height: 20px;
            //  padding: 0;
            //  border: none;
            //  outline: none;
            //  appearance: none;
            //  background: $transparent;
            //  position: absolute;
            //  bottom: calc(65% - 20px);
            //  right: 10px;
            //  display: flex;
            //  align-items: flex-start;
            //  justify-content: flex-end;
            //  cursor: pointer;
            //
            //  &::before {
            //    content: "t";
            //    width: 4px;
            //    height: 13px;
            //    font-size: 13px;
            //    font-family: $font-icon;
            //    color: $white;
            //    position: absolute;
            //    pointer-events: none;
            //  }
            //
            //}

            //.menu-container {
            //  background: transparentize($white, 0.9);
            //
            //  .menu-item {
            //    border: none;
            //    outline: none;
            //    appearance: none;
            //    @include font-style(14px, $font-regular, $white, 0, 100%);
            //  }
            //
            //}

            .text-container {
              width: 100%;
              display: flex;
              justify-content: flex-end;
              align-items: flex-start;
              flex-flow: column;
              padding-right: 20px;
              position: relative;

              .title {
                width: 100%;
                @include font-style(12px, $font-medium, $white, 0, 100%);
                word-break: break-all;
                cursor: pointer;
                margin-bottom: 5px;
              }

              .date {
                width: 100%;
                @include font-style(10px, $font-medium, $brand-color-09, 0, 100%);
                cursor: pointer;
              }

              .count {
                @include font-style(10px, $font-medium, $brand-color-09, 0, 100%);
                cursor: pointer;
                position: absolute;
                right: 0;
                bottom: 0;
              }

            }

          }

          &.loading {

            .image-container {
              border: none;
              border-radius: 5px;
              @include skeleton-loading;
            }

            .description-container {

              .name {
                width: 60%;
                height: 20px;
                border-radius: 3px;
                @include skeleton-loading;
              }

            }

          }

          @media (hover: hover) and (pointer: fine) {

            &:hover {

              .image-container {

                img {
                  opacity: 0.5;
                }

              }

            }

          }

          .image-container {
            width: 100%;
            height: auto;
            aspect-ratio: 1;
            border: 1px solid $brand-color-08;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              aspect-ratio: 1;
              object-fit: cover;
              object-position: center;
              transition: $standard-transition;
            }

            .format {
              max-width: calc(100% - 40px);
              word-break: break-all;
              position: absolute;
              right: 5px;
              bottom: 5px;
              padding: 4px 5px;
              border-radius: 3px;
              background: $brand-color-08;
              @include font-style(10px, $font-semibold, $white, 0, 100%);
              cursor: pointer;
              text-transform: uppercase;
            }

            mat-checkbox {
              @include mat-checkbox-style(transparentize($brand-color-08, 0.5), $primary, none);
              position: absolute;
              left: 5px;
              bottom: 5px;
            }

          }

          .description-container {
            width: 100%;
            margin-top: 10px;

            .name {
              width: 100%;
              display: flex;
              @include font-style(12px, $font-regular, $white, 0, 150%);
              cursor: pointer;
              //overflow: hidden;
              //display: -webkit-box;
              //-webkit-box-orient: vertical;
              //-webkit-line-clamp: 1;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

          }

        }

      }

    }

    .buttons-container {
      width: 100%;
      margin-bottom: 20px;
      @include flex-center;

      button.more {
        @include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true);
      }

    }

  }


  .no-results-container {
    @include no-results;
  }

}
