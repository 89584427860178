@import 'variables.scss';

/* --- SPECIFIC MIXINS --- */

@mixin input-checkbox {
	width: 18px;
	height: 18px;
	margin: 0;
	@include flex-center;
	appearance: none;
	cursor: pointer;
	position: relative;
	background: $transparent;

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;

		&::before {
			cursor: not-allowed;
		}

		&::after {
			cursor: not-allowed;
		}

	}

  &:indeterminate {

    &::after {
      content: '';
      width: 1px;
      height: 4px;
      background: $white;
      position: absolute;
      margin-bottom: 2px;
      transform: rotate(0);
      opacity: 1;
      z-index: 1;
      cursor: pointer;
      transition: $standard-transition;
    }

  }

	&::before {
		content: '';
		width: 18px;
		height: 18px;
		position: absolute;
		background: $brand-color-06;
		border-radius: 5px;
		z-index: 0;
		cursor: pointer;
	}

	&::after {
		content: '';
		width: 7px;
		height: 4px;
		position: absolute;
		border-bottom: 2px solid $white;
		border-left: 2px solid $white;
		margin-bottom: 2px;
		transform: rotate(-45deg);
		opacity: 0;
		z-index: 1;
		cursor: pointer;
		transition: $standard-transition;
	}

	&:checked {

		&::before {
			background: $brand-color-04;
		}

		&::after {
			opacity: 1;
			background: $transparent;
		}

	}

}

//Campo slider/range tipo per dimensione elementi media library
@mixin mat-slider {
	min-width: 200px;
	--mat-mdc-slider-focus-ripple-color: none;

	.mdc-slider__track {
		height: 1px;
		background: $brand-color-08;

		.mdc-slider__track--inactive {
			height: 1px;
			background: $brand-color-08;
		}

		.mdc-slider__track--active {
			display: none;
		}

	}

	mat-slider-visual-thumb {

		.mdc-slider__thumb-knob {
			border-radius: 3px;
			border: 1px solid $brand-color-08;
			background: $brand-color-02;
			box-shadow: none;
		}

	}

	/* PRIMA, DA CAPIRE SE SERVE ANCORA */
	.mat-mdc-slider-wrapper {
		height: 1px;
		background: $brand-color-08;

		.mat-mdc-slider-track-wrapper {
			height: 1px;
			background: $brand-color-08;
		}

		.mat-mdc-slider-ticks-container {
			height: 1px;
			background: $brand-color-08;
		}

		.mat-mdc-slider-thumb-container {

			.mat-mdc-slider-focus-ring,
			.mat-mdc-slider-thumb-label {
				display: none;
			}

			.mat-mdc-slider-thumb {
				transform: none;
				width: 19px !important;
				height: 19px !important;
				border: 1px solid $brand-color-08;
				background: $brand-color-01;
				border-radius: 3px;
			}

		}

	}

	::ng-deep {

		.mdc-slider__track {
			height: 1px;
			background: $brand-color-08;

			.mdc-slider__track--inactive {
				height: 1px;
				background: $brand-color-08;
			}

			.mdc-slider__track--active {
				display: none;
			}

		}

		mat-slider-visual-thumb {

			.mdc-slider__thumb-knob {
				border-radius: 3px;
				border: 1px solid $brand-color-08;
				background: $brand-color-02;
				box-shadow: none;
			}

		}

		/* PRIMA, DA CAPIRE SE SERVE ANCORA */
		.mat-mdc-slider-wrapper {
			height: 1px;
			background: $brand-color-08;

			.mat-mdc-slider-track-wrapper {
				height: 1px;
				background: $brand-color-08;
			}

			.mat-mdc-slider-ticks-container {
				height: 1px;
				background: $brand-color-08;
			}

			.mat-mdc-slider-thumb-container {

				.mat-mdc-slider-focus-ring,
				.mat-mdc-slider-thumb-label {
					display: none;
				}

				.mat-mdc-slider-thumb {
					transform: none;
					width: 19px !important;
					height: 19px !important;
					border: 1px solid $brand-color-08;
					background: $brand-color-01;
					border-radius: 3px;
				}

			}

		}

	}

}

//@include mat-checkbox-style(transparentize($white, 0.9), $primary, none);
@mixin mat-checkbox-style($background, $backgroundActive, $borderColor) {

	.mdc-form-field {

		.mdc-checkbox {
			padding: 0;

			.mat-mdc-checkbox-touch-target {
				display: none;
			}

			input {
				width: 18px;
				height: 18px;

				&:enabled:checked ~ .mdc-checkbox__background {
					border-color: unset;
					background-color: $backgroundActive !important;
				}

				&:enabled:indeterminate ~ .mdc-checkbox__background,
				&.mdc-checkbox--selected ~ .mdc-checkbox__background {
					border-color: unset;
					background-color: $backgroundActive !important;

					.mdc-checkbox__mixedmark {
						transform: scale(0.5);
						border-color: $white !important;
					}

				}

			}

			.mdc-checkbox__ripple {
				display: none;
			}

			.mdc-checkbox__background {
				width: 18px;
				height: 18px;
				top: unset;
				left: unset;
				border-radius: 5px;
				background-color: $background !important;
				border: $borderColor !important;

				svg {
					transform: scale(0.6);
				}

				.mdc-checkbox__mixedmark {
					transform: scale(0);
				}

			}

			.mat-mdc-checkbox-ripple {
				display: none;
			}

		}

		.mdc-label {
			padding-left: 10px;
			@include font-style(12px, $font-regular, $white, 0, 150%);
		}

	}

}

/* Stile per buttons che al click aprono un sottomenù */
@mixin button-mat-menu($content, $background, $color, $size, $backgroundActive, $colorActive) {
	@include flex-center;
	cursor: pointer;
	background: none;
	padding: 0;
	outline: 0;
	border: none;
	position: relative;

	&::before {
		content: '';
		width: 100%;
		height: 10px;
		background: $background;
		position: absolute;
		bottom: -10px;
		opacity: 0;
	}

	&::after {
		content: $content;
		width: 100%;
		height: 100%;
		font-family: $font-icon;
		font-size: $size;
		position: absolute;
		color: $color;
		cursor: pointer;
		@include flex-center;
	}

	&.mat-menu-below {
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;

		&::before {
			bottom: -10px;
		}

	}

	&.mat-menu-above {
		border-bottom-left-radius: 3px;
		border-bottom-right-radius: 3px;

		&::before {
			top: -10px;
		}

	}

	&[aria-expanded='false'] {
		box-shadow: none;
	}

	&[aria-expanded='true'] {
		background: $backgroundActive;

		&::before {
			opacity: 1;
		}

		&::after {
			color: $colorActive;
		}

	}

	@media (hover: hover) and (pointer: fine) {

		&:hover {
			opacity: 0.5;
		}

	}

	mat-icon {
		display: none;
	}

}

@mixin right-options-detail {
	height: 100%;
	width: 350px;
	min-width: 350px;
	padding: 40px 30px 20px 30px;
	background: $brand-color-13;
	overflow-x: hidden;
	overflow-y: auto;
	@include scroll-bar;
	display: flex;
	flex-flow: column;
	row-gap: 20px;

  .has-draft-banner {
    width: 100%;
    height: auto;
    padding: 20px;
    background: $primary;
    border-radius: 10px;
    @include flex-center;

    .text {
      @include font-style(15px, $font-regular, $white, 0, 130%);
      text-align: center;
    }

  }

	.statistics-container {
		width: 100%;
		height: auto;
		padding: 20px;
		background: linear-gradient(to right, $brand-color-02, $brand-color-13);
		border-radius: 10px;
		display: flex;
		flex-flow: column;
		row-gap: 20px;
	}

	.percentage-indicator-container {
		width: 100%;
		display: flex;
		align-items: center;

		datalean-percentage-indicator::ng-deep {
			width: 60%;

			.percentage-container {

				.details {

					.number,
					.percentage {
						@include font-style(15px, $font-regular, $white, 0, 150%);
					}

				}

			}

		}

		label {
			width: 40%;
			@include font-style(13px, $font-regular, $brand-color-09, 0, 130%);
			text-align: right;
		}

	}

  form {
    display: flex;
    flex-flow: column;
    gap: 20px;
  }

	.fields-container {
		@include form-fields-container;

		.field-container {
			padding: 0;
		}

	}

	.buttons-options-container {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		row-gap: 20px;
		column-gap: 20px;

		button {
			width: 100% !important;
			padding: 5px 20px !important;
			border-radius: 10px !important;

			&:not(.save),
			&:not(.delete) {
				/* permission clone preview product-variants */
				@include button-style(36px, $white, $white, $buttonIcon: false, $buttonBackground: false);
			}

			&.save {
				@include button-style(36px, $brand-color-04, $white, $buttonIcon: false, $buttonBackground: true, $buttonSize: 'big');
        padding: 10px 20px !important;
			}

			&.delete {
				@include button-style(36px, $red-01, $red-01, $buttonIcon: false, $buttonBackground: false);
			}

			&.ratio-100 {
				width: 100% !important;
			}

			&.ratio-50 {
				width: calc(50% - 10px) !important;
			}

		}

	}

	.anchors-list-container {
		width: 100%;
		display: flex;
		flex-flow: column;
		row-gap: 10px;
		margin-top: 20px;

		.single-anchor {
			width: 100%;
			min-height: 40px;
			display: flex;
			align-items: center;
			position: relative;

			&::before {
				content: '';
				width: 0;
				height: 100%;
				background: $brand-color-04;
				position: absolute;
				left: -30px;
				transition: $standard-transition;
			}

			&.active {

				&::before {
					width: 3px;
				}

				a {
					color: $white;
				}

			}

			a {
				width: 100%;
				max-width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				@include font-style(19px, $font-regular, transparentize($white, 0.6), 0.8px, 130%);
				cursor: pointer;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				transition: $standard-transition;

				@media (hover: hover) and (pointer: fine) {

					&:hover {
						color: $white;
					}

				}

			}

		}

	}

}

@mixin mat-tab-header {
	border-bottom: 1px solid transparentize($brand-color-08, 0.7);

	button.mat-ripple {
		display: none;
	}

	.mat-mdc-tab-label-container {

		.mat-mdc-tab-list {

			.mat-mdc-tab-labels {
				display: flex;
				column-gap: 20px;

				.mat-mdc-tab {
					width: auto;
					flex: unset;
					padding: 0;

					&.mdc-tab--active {

						.mdc-tab__content {

							.mdc-tab__text-label {
								color: $white;
							}

						}

					}

					.mdc-tab__ripple {
						display: none;
					}

					.mat-ripple {
						display: none;
					}

					.mdc-tab__content {

						.mdc-tab__text-label {
							@include font-style(12px, $font-medium, transparentize($white, 0.5), 1.2px, 100%);
							cursor: pointer;
							text-transform: uppercase;
							transition: $standard-transition;
						}

					}

					.mdc-tab-indicator {

						.mdc-tab-indicator__content--underline {
							border-color: $brand-color-09;
							border-width: 1px;
							transition: $standard-transition;
						}

					}

				}

			}

		}

	}

}

@mixin structureIcons {

	&::before {
		width: 20px;
		height: 20px;
		font-size: 16px;
		font-family: $font-icon-structure;
		color: $brand-color-08;
		@include flex-center;
	}

	&.container {
		&::before {
			content: 'b';
		}
	}

	&.attachment_3\.0 {
		&::before {
			content: 'q';
		}
	}

	&.text {
		&::before {
			content: 'l';
		}
	}

	&.urlTitle {
		&::before {
			content: 'r';
			font-size: 13px;
		}
	}

	&.textarea {
		&::before {
			content: 'd';
		}
	}

	&.file_3\.0 {
		&::before {
			content: 'c';
		}
	}

	&.checkbox,
	&.boolean {
		&::before {
			content: 'g';
		}
	}

	&.toggle {
		&::before {
			content: 's';
			font-size: 13px;
		}
	}

	&.html {
		&::before {
			content: 'e';
			font-size: 13px;
		}
	}

	&.phone_3\.0 {
		&::before {
			content: 'p';
		}
	}

	&.email_3\.0 {
		&::before {
			content: 'f';
		}
	}

	&.select {
		&::before {
			content: 'a';
		}
	}

	&.radio {
		&::before {
			content: 'n';
		}
	}

	&.privacy {
		&::before {
			content: 'j';
		}
	}

	&.qrcode {
		&::before {
			content: 'h';
		}
	}

	&.hotspotter {
		&::before {
			content: 'm';
		}
	}

	&.imageMapContainer_3\.0 {
		&::before {
			content: 't';
		}
	}

	&.featureValuesContainer {
		&::before {
			content: 'u';
		}
	}

	&.relatedEntities {
		&::before {
			content: 'o';
		}
	}

	&.date {
		&::before {
			content: 'v';
		}
	}

	&.number {
		&::before {
			content: 'w';
		}
	}

	&.hidden {
		&::before {
			content: 'x';
		}
	}

	&.surveyDomainOfAnswers {
		&::before {
			content: 'z';
		}
	}

	&.pictogram {
		&::before {
			content: 'y';
		}
	}

	&.availabilityOption {
		&::before {
			content: 'B';
		}
	}

	&.availabilityRule {
		&::before {
			content: 'C';
		}
	}

  &.substructure {
    &::before {
      content: 'A';
    }
  }

}

@mixin view-mode-buttons {
	@include flex-center;

	.view-button {
		width: 20px;
		height: 36px;
		background: $transparent;
		padding: 0;
		border: none;
		border-radius: 0;
		position: relative;
		@include flex-center;
		cursor: pointer;
		transition: $standard-transition;
		margin-left: 20px;

		@media (hover: hover) and (pointer: fine) {

			&:hover {
				opacity: 0.5;
			}

		}

		&::before {
			content: 'v';
			width: 16px;
			height: 16px;
			color: $white;
			font-size: 16px;
			font-family: $font-icon;
			position: absolute;
			cursor: pointer;
			transition: $standard-transition;
			@include flex-center;
			pointer-events: none;
		}

		&.active {

			&::before {
				color: $brand-color-14;
			}

			@media (hover: hover) and (pointer: fine) {

				&:hover {
					opacity: 1;
				}

			}

		}

		&.list-view {
			&::before {
				content: 'A';
			}
		}

		&.grid-view {
			&::before {
				content: 'z';
			}
		}

	}

}

@mixin  header-with-search() {
  width: 100%;
  height: 70px;
  padding: 10px;
  display: flex;
  margin-bottom: 20px;

  .left-container {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: auto;

    .title {
      width: calc(100% - 30px);

      h1,
      h2,
      h3 {
        @include font-style(20px, $font-light, $white, 0, 150%);
        text-overflow: ellipsis;
        overflow: hidden;
        margin: 0;
      }

    }

  }

  .right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    addiction-search { /* stile dentro addiction-components.scss */ }

    button.add {
      @include button-style("e", $brand-color-04, $white, $buttonIcon: true, $buttonBackground: true);
      margin-left: 20px;
    }

  }

}
